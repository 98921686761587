let iconAnchor = new google.maps.Point(22, 22);
let iconSize = new google.maps.Size(44, 44);

export let icon = {
  url: window.location.protocol + "//" + window.location.hostname + '/assets/images/marker-pin.svg',
  anchor: iconAnchor,
  scaledSize: iconSize,
  size: iconSize,
  optimize: false
};

export let iconActive = {
  url: window.location.protocol + "//" + window.location.hostname + '/assets/images/marker-pin-active.svg',
  anchor: iconAnchor,
  scaledSize: iconSize,
  size: iconSize,
  optimize: false
};

export let mapStyles = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#ff9400"}]},{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"labels.text.fill","stylers":[{"color":"#ff9400"}]},{"featureType":"road.local","elementType":"all","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#ff9400"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]}];

export let mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  streetViewControl: false,
  signInControl: false,
  scrollwheel: false,
  scaleControl: false,
  rotateControl: false,
  panControl: true,
  mapTypeControl: false,
  fullscreenControl: false,
  draggable: true,
  cursor: 'default',
  draggableCursor: 'default'
};
