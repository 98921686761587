// NPM dependencies
import $ from 'jquery';
import 'babel-polyfill';
import 'foundation-sites';
import 'slick-carousel';
import 'lightgallery';
import objectFitImages from 'object-fit-images';

var AOS = require('aos');

// Project Partials
// import Object from '../../src/components/03-ui/component-folder/component-file-name';
import maps from '../../src/components/03-ui/content-map/maps';
import mainNav from '../../src/components/03-ui/site-header/site-header';
import contentSlider from '../../src/components/03-ui/content-slider/content-slider';
import contentGallery from '../../src/components/03-ui/content-gallery/content-gallery';

// Project Modules
// import particles from './modules/particles';

// Note 1: if using VUE, your vue version and vue-template-compiler packages in package.json should match version numbers exactly.
// Note 2: if using VUE, import like this:

// import ExampleComponent from './components/ExampleComponent.vue';
// Vue.component('example-component', ExampleComponent);

$(document).ready(() => {
  // Init foundation on doc ready
	$(document).foundation();

  // Object Fit Polyfill
  // https://github.com/bfred-it/object-fit-images
	objectFitImages();

  // Init Animate on Scroll
  // https://michalsnik.github.io/aos/
	AOS.init();

  // Initialise imported js (this would be js required for every page, globally, i.e. headers
  // Object.init();
	maps.init();
	mainNav.init();
	contentSlider.init(AOS);
	contentGallery.init();

	// particles.init();

	$('[data-accordion-menu]').on('down.zf.accordionMenu', function () {
    // var accordionMenu = $(this);
    // console.log(accordionMenu);
		$('.sticky').foundation('_calc', true);
	});

	$('[data-accordion-menu]').on('up.zf.accordionMenu', function () {
    // var accordionMenu = $(this);
    // console.log(accordionMenu);
		$('.sticky').foundation('_calc', true);
	});

  // Conditionally import and initialise additional chunks
  // See @ https://webpack.js.org/guides/code-splitting/ && https://sebastiandedeyne.com/posts/2018/code-splitting-with-laravel-mix
  // Depending on the page, these additional components may or may not be needed.
});
